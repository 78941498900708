<template>
  <div>
    <!-- <div>
      <el-menu
        :default-active="activeIndex"
        @select="handleMenuSelect"
        mode="horizontal"
      >
        <el-menu-item index="1" route="/dealer/subscriptions/manage"
          >Manage subscriptions</el-menu-item
        >
        <el-menu-item index="2" route="/dealer/subscriptions/year-wise"
          >Subscription Summary</el-menu-item
        >
      </el-menu>
    </div> -->
    <el-card class="layout-dealer-card">
      <layout-toolbar slot="header">
        <slot name="heading"></slot>
        <div class="filler">
          <div class="button-group" v-if="hasElementInLayout('action_btn')">
            <el-button type="success" size="small" @click="downloadData">
              <i class="el-icon-download"></i>
            </el-button>
          </div>
          <div class="switch-button" v-if="hasElementInLayout('switch')">
            <el-switch
              v-model="finalPriceSwitch"
              active-text="Discount %"
              inactive-text="Price"
            >
            </el-switch>
          </div>
        </div>
        <el-col :span="3" v-if="hasElementInLayout('plan_name')">
          <el-select placeholder="Plan Name" size="mini" clearable></el-select>
        </el-col>
        <el-col :span="3" v-if="hasElementInLayout('plan_type')">
          <el-select placeholder="Play Type" size="mini" clearable></el-select>
        </el-col>
        <el-col :span="3" v-if="hasElementInLayout('last_updated')">
          <el-input
            placeholder="Last Updated"
            v-model="lastUpdated"
            type="datetime"
            size="mini"
            default-time="12:00:00"
          ></el-input>
        </el-col>
        <el-col :sm="4" v-if="hasElementInLayout('search')">
          <el-input
            :placeholder="searchTranslationKeyName"
            :debounce="300"
            v-model="searchString"
            size="mini"
            clearable
            @change="handleChange"
            @clear="handleClear"
            class="search-input"
            autofocus="true"
            ref="mainSearch"
          ></el-input>
        </el-col>
        <el-col :span="4" v-if="hasElementInLayout('year_filter')&&!isSTBlock">
          <el-date-picker
            v-if="hasElementInLayout('year_filter')"
            v-model="selectedYear"
            type="year"
            size="mini"
            @change="handleYearChange"
            placeholder="Select year"
          >
          </el-date-picker>
        </el-col>
        <div class="button-group" v-if="hasElementInLayout('blockST_btn')&&isSTBlock">
          <el-button type="success" size="small" @click="handleOpenDialog">
            <span class="material-icons-outlined action-icon"> queue </span>
          </el-button>
        </div>
        <div class="button-group" v-if="hasElementInLayout('download_btn')&&dropdownDownload===false">
            <el-button type="success" size="small" @click="downloadData">
              <i class="el-icon-download"></i>
            </el-button>
          </div>
          <div class="button-group" v-if="hasElementInLayout('download_btn')&&dropdownDownload===true">
            <!-- <el-popover
              popper-class="el-popover-dropdown"
              placement="bottom"
              trigger="click">
                <el-button style="color: black;padding: 5px;" size="small" type="text" @click="downloadData">
                  Summary
                </el-button><br>
                <el-button size="small" style="color: black;padding: 5px;" type="text" @click="downloadRawData">
                  Raw Data
                </el-button>
                <el-button slot="reference" type="success" size="small" @click="visible = !visible">
                  <i class="el-icon-download"></i>
                </el-button>
            </el-popover> -->
            <el-dropdown trigger="click" @command="downloadRawData">
              <el-button type="success" size="small">
                <i class="el-icon-download"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="font-size: 13px !important; color: #616972 !important; height: 2rem;" command="summary">Summary</el-dropdown-item>
                <el-dropdown-item style="font-size: 13px !important; color: #616972 !important; height: 2rem;" command="rawData">Raw Data</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        <el-col :span="2" v-if="hasElementInLayout('device_toggle')">
          <div class="toggle-box" style="text-align: center">
            <span style="font-size: 12px;font-weight: 700;">Devices</span>
            <el-switch
              :value="isSTBlock"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @input="handleEnableFeature"
            ></el-switch>
          </div>
        </el-col>
        <!-- <div class="button-group" v-if="hasElementInLayout('download_btn')">
          <el-button type="success" size="small" @click="downloadData">
            <i class="el-icon-download"></i>
          </el-button>
        </div> -->
        <er-button
          v-if="hasElementInLayout('button')"
          btnType="add"
          size="mini"
          :showLabel="true"
          :showIcon="true"
          @click="handleAddButtonClick"
        >
          <slot name="add-buttton">
            {{ ftm__capitalize($t("dealer.assign_to_user")) }}
          </slot>
        </er-button>
        <slot name="table-toolbar-left-slot"> </slot>
      </layout-toolbar>
      <slot name="table"></slot>
      <slot name="table-legends"></slot>
    </el-card>
    <slot name="table-footer"> </slot>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  props: {
    toolbarLayout: {
      default: "button,search",
      type: String,
    },
    placeHolderForSearch: {
      default: "Comn_search",
      type: String,
    },
    searchProperty: {
      default: "code",
      type: String,
    },
    queryParams: {
      type: Object,
      default: () => ({}),
    },
    dropdownDownload: {
      default: false,
      type: Boolean,
    },
    isSTBlock: {
      default: false,
      type: Boolean,
    },
  },
  data: function () {
    return {
      activeIndex: "1",
      searchString: "",
      selectedYear: "2021",
      query: {},
      limit: 30,
      page: 1,
      lastUpdated: "",
      finalPriceSwitch: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  mixins: [filtersMixin, errorHandlerMixin],
  computed: {
    searchTranslationKeyName() {
      if (this.placeHolderForSearch && this.$t(this.placeHolderForSearch)) {
        return this.$t(this.placeHolderForSearch);
      }
      return this.$t("Comn_search");
    },
  },
  mounted() {
    this.selectedYear = new Date().getFullYear().toString();
  },
  methods: {
    handleMenuSelect(key, keyPath) {
      this.activeIndex = key;

      if (key === "1") {
        if (this.$route.path !== "/dealer/subscriptions/manage") {
          this.$router.push("/dealer/subscriptions/manage");
        }
      } else {
        if (this.$route.path !== "/dealer/subscriptions/year-wise") {
          this.$router.push("/dealer/subscriptions/year-wise");
        }
      }
    },

    hasElementInLayout(element) {
      return this.toolbarLayout.includes(element);
    },
    handleAddButtonClick() {
      this.$emit("add-item-click");
    },
    async handleClear() {
      await this.$emit("cleared");
    },

    async handleYearChange(year) {
      this.query.year = year;
      await this.$emit("loadData", this.query);
    },
    handleEnableFeature(event) {
      this.$emit("toggleChange", event);
    },
    async downloadData() {
      await this.$emit("onDownloadData", { status: true });
    },
    async handleChange(string) {
      if (string === "") {
        this.query.page = this.queryParams.page || this.page;
        this.query.limit = this.queryParams.limit || this.limit;
        this.query.search = false;
        delete this.query[this.searchProperty];
      } else {
        this.query.page = this.queryParams.page || this.page;
        this.query.search = true;
        this.query.limit = this.queryParams.limit || this.limit;
        this.query[this.searchProperty] = string;
      }
      await this.$emit("loadData", this.query);
    },
    async downloadRawData(command) {
      if (command === 'summary') {
        this.downloadData()
      } else {
        await this.$emit("onDownloadRawData", { status: true });
      }
    },
    handleOpenDialog() {
      this.$emit("add-group-settings-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-dealer-card {
  border-radius: 7px;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  &::v-deep .el-card__header {
    padding: 5px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .card-icon {
      @include icon-size(svg);
      margin-right: 10px;
    }
  }
  .el-col-4 {
    width: 15.5%;
  }
  &::v-deep .filler {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 14px;
    padding: 5px 10px 5px 0px;
    .button-group {
      .el-button {
        line-height: 1;
        padding: 5px 8px;
        font-size: 1em;
        height: auto;
      }
    }
  }
  &::v-deep .button-group {
    .el-button {
      line-height: 1;
      padding: 4px 5px;
      font-size: 1em;
      height: auto;
    }
  }
  &::v-deep .el-card__body {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex-grow: 1;
    .sub-heading {
      margin: 20px;
    }
  }
  .el-dropdown-menu__item-layout {
    font-size: 13px !important;
  }
}
.material-icons-outlined {
  line-height: 1;
  padding: 0px 0px;
  font-size: 1em;
  height: auto;
}
// .action-icon {
//     cursor: pointer;
//     color: white;
//     // opacity: 0.3;
//     font-weight: 500;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     @include icon-size(icon-font);
//     // &:hover {
//     //   opacity: 1;
//     //   cursor: pointer;
//     // }
//   }
</style>
